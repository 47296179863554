import React, { useEffect, useState, useRef } from "react";
// import "./add-user-info.scss";

import { toast } from "react-toastify";
import InputField from "@_common/InputRuleFeild";
import { getValue, setValue } from "@utils/lodash";
import {
  adminGetAllRoles,
  createLegalUpdate,
  editLegalUpdate,
  getSpecificLegal,
  legalUpdate,
} from "@services/roles.service";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import countries from "@_common/contry.json";
import Layout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import _ from "lodash";
import NoAccessPage from "@components/common/NoAccess";
import Loader from "@components/common/Loader";
import { NavLink } from "react-router-dom";
import { QueryRequestHelper } from "@_common/query-request-helper";
import {
  adminGetCountriesListManagement,
  adminGetListManagementGroupCode,
  adminGetStatesListManagement,
  adminListManagementList,
} from "@services/list-management.service";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import base64 from "base-64";
import { formatSmallString } from "@_common/format-text";
import DragAndDropFileComponent from "@_common/file-drag-drop";
import { vendorFileUpload } from "@services/auth.service";
import { handleDownloadOnClick } from "@_common/downloadfile";

interface ILegalUpdateDetailsInfoProps {}

const LegalUpdateDetailsInfo: React.FunctionComponent<
  ILegalUpdateDetailsInfoProps
> = (props: any) => {
  let orgParams = useParams();
  let params = useParams();
  let navigate = useNavigate();

  const urlParamsFun = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let request = QueryRequestHelper({ ...params });
    return request;
  };

  const mainQueryRequest = urlParamsFun();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const intialState = {
    title: "",
    content: "",
    author: "",
    tags: [],
    industries: [],
    regulations: "",
    ministries: "",
    departments: "",
    notification_type: "",
    states: "",
    country_id: null,
    documents: [],
    document_link: "",
    act_name: "",
    // send_invite: false,
  };
  const [request, setRequest] = useState(intialState);

  const [dirtyFields, setDirtyFields] = useState<any>(intialState);

  const reset = () => {
    setRequest(intialState);
    setDirtyFields(intialState);
  };

  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rolesList, setRolesList] = useState([]);
  const [legalList, setLegalList] = React.useState<[]>([]);

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page_no, setPage_no] = useState(1);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };
  const [errors, setErrors] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(params, `id`, "") && props.permissionAPITriggered) {
        getSpecificLegalUpdate(getValue(params, `id`, ""));
      } else {
        setLoading(false);
      }
      getTagsList("", "");
      getIndustryList("", "");
      getStatesList(101);
      getRegulationList();
      getMinistryList();
      getDepartmentList();
      getNotificationTypeList();
      getCountriesList();
      getActNameList();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (request.country_id) { // Ensure country_id is valid before calling the function
      getStatesList(request.country_id);
    }
  }, [request.country_id]);
  


  const urlSearchParams = new URLSearchParams(window.location.search);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [publication_date, setPublication_date] = useState<any>(null);
  const [effective_date, setEffective_date] = useState<any>(null);


  const convertDate = (date: any) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return null;
    }
  };

  const getPayload = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let industrySelectedIDs: any = urlSearchParams.getAll("industries[]");
    let regulationSelectedIDs: any = urlSearchParams.getAll("regulations[]");
    let ministrySelectedIDs: any = urlSearchParams.getAll("ministries[]");
    let departmentSelectedIDs: any = urlSearchParams.getAll("departments[]");
    let statesSelectedIDs: any = urlSearchParams.getAll("states[]");
    let tagsSelectedIDs: any = urlSearchParams.getAll("tags[]");
    let notificationTypeSelectedIDs: any = urlSearchParams.getAll(
      "notification_type[]"
    );

    // let notificationTypeSelectedIDs: any = urlSearchParams.getAll("notification_type");

    // let compliance_task_ids: any = urlSearchParams.getAll(
    //   "compliance_task_ids[]"
    // );

    let dates = params.date ? new Date(params.date) : new Date();
    var from_date = params.from_date
      ? params.from_date
      : new Date(dates.getFullYear(), dates.getMonth(), 1);
    var to_date = params.to_date
      ? params.to_date
      : new Date(dates.getFullYear(), dates.getMonth() + 1, 0);
    let payload: any = {
      from_date: getValue(params, `is_all_time`, "")
        ? ""
        : getValue(params, `from_date`, "")
        ? getValue(params, `from_date`, "")
        : convertDate(from_date),
      to_date: getValue(params, `is_all_time`, "")
        ? ""
        : getValue(params, `to_date`, "")
        ? getValue(params, `to_date`, "")
        : convertDate(to_date),
      // compliance_status: getValue(params, `compliance_status`, ""),
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      sort_by: params.sort_by,
      search_text: params.search_text,
      country_id: parseInt(getValue(params, `country_id`, "")),
      // state_id: parseInt(getValue(params, `state_id`, "")),

      industries:
        industrySelectedIDs.length > 0
          ? industrySelectedIDs.map((item: string) => ({ id: item }))
          : [],
      regulations:
        regulationSelectedIDs.length > 0
          ? regulationSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      ministries:
        ministrySelectedIDs.length > 0
          ? ministrySelectedIDs.map((item: string) => ({ id: item }))
          : [],
      departments:
        departmentSelectedIDs.length > 0
          ? departmentSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      states:
        statesSelectedIDs.length > 0
          ? statesSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      tags:
        tagsSelectedIDs.length > 0
          ? tagsSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      notification_type:
        notificationTypeSelectedIDs.length > 0
          ? notificationTypeSelectedIDs.map((item: string) => ({ id: item }))
          : [],
      // notification_type : notificationTypeSelectedIDs[0]

      // status: getValue(params, `status`, "")
      //   ? getValue(params, `status`, "")
      //   : "active",
      // request_from:
      //   getValue(compliance_task_ids, `length`, 0) > 0
      //     ? ""
      //     : getValue(params, `request_from`, "")
      //     ? getValue(params, `request_from`, "")
      //     : "MASTER",
    };
    return { ...payload };
  };

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      sort_by: params.sort_by,
      is_active: true,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    // let queryRequest = qs.stringify(payload);
    let payloadObj = await getPayload();
    let queryRequest = QueryRequestHelper({
      ...payload,
      ...payloadObj,
    });
    try {
      setIsLoading(true);
      let resp = await legalUpdate({ ...payload, ...payloadObj });
      if (resp) {
        setLegalList(getValue(resp, `data.blogData`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSpecificLegalUpdate = async (id: string) => {
    try {
      setLoading(true);
      let resp = await getSpecificLegal({ id: JSON.parse(id) });
      if (resp) {
        setRequest({
          ...request,
          title: getValue(resp, `data.blogData[0].title`, ""),
          author: getValue(resp, `data.blogData[0].author`, ""),
          act_name: getValue(resp, `data.blogData[0].act_name.id`, ""),
          states: getValue(resp, `data.blogData[0].states.id`, ""),
          country_id: getValue(resp, `data.blogData[0].country.id`, ""),
          regulations: getValue(resp, `data.blogData[0].regulations.id`, ""),
          ministries: getValue(resp, `data.blogData[0].ministries.id`, ""),
          departments: getValue(resp, `data.blogData[0].departments.id`, ""),
          notification_type: getValue(
            resp,
            `data.blogData[0].notification_type.id`,
            ""
          ),
          document_link: getValue(resp, `data.blogData[0].document_link`, ""),
        });
        setDirtyFields({
          ...dirtyFields,
          title: getValue(resp, `data.blogData[0].title`, ""),
          author: getValue(resp, `data.blogData[0].author`, ""),
          act_name: getValue(resp, `data.blogData[0].act_name.id`, ""),
          states: getValue(resp, `data.blogData[0].states.id`, ""),
          country_id: getValue(resp, `data.blogData[0].country.id`, ""),
          regulations: getValue(resp, `data.blogData[0].regulations.id`, ""),
          ministries: getValue(resp, `data.blogData[0].ministries.id`, ""),
          departments: getValue(resp, `data.blogData[0].departments.id`, ""),
          notification_type: getValue(
            resp,
            `data.blogData[0].notification_type.id`,
            ""
          ),
          document_link: getValue(resp, `data.blogData[0].document_link`, ""),
        });

        setSelectedTagsListIds(
          getValue(resp, `data.blogData[${0}].tags.length`, 0) > 0
            ? getValue(resp, `data.blogData[${0}].tags`, []).map(
                (item: object) => ({
                  id: getValue(item, `id`, ""),
                  title: getValue(item, `text`, ""),
                  value: getValue(item, `text`, ""),
                  label: getValue(item, `text`, ""),
                })
              )
            : []
        );

        setSelectedIndustryListIds(
          getValue(resp, `data.blogData[${0}].industries.length`, 0) > 0
            ? getValue(resp, `data.blogData[${0}].industries`, []).map(
                (item: object) => ({
                  id: getValue(item, `id`, ""),
                  title: getValue(item, `text`, ""),
                  value: getValue(item, `text`, ""),
                  label: getValue(item, `text`, ""),
                })
              )
            : []
        );
        setDocuments(getValue(resp, `data.blogData[${0}].documents`, []));

        let content: any = getValue(resp, `data.blogData[${0}].content`, "");
        let convertedHTML: any = convertFromHTML(content);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertedHTML)
          )
        );
        if (getValue(resp, `data.blogData[${0}].publication_date`, null)) {
          setPublication_date(
            new Date(
              getValue(resp, `data.blogData[${0}].publication_date`, null)
            )
          );
        }

        if (getValue(resp, `data.blogData[${0}].effective_date`, null)) {
          setEffective_date(
            new Date(
              getValue(resp, `data.blogData[${0}].effective_date`, null)
            )
          );
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    let contentData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const formValid = simpleValidator.current.allValid();
    let selectedIndustryListIdsPayload = JSON.parse(
      JSON.stringify(selectedIndustryListIds)
    );
    let selectedTagsListIdsPayload = JSON.parse(
      JSON.stringify(selectedTagsListIds)
    );

    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const plainText = contentState.getPlainText();

    let publicationDate = JSON.parse(JSON.stringify(publication_date));
    let effectiveDate = JSON.parse(JSON.stringify(effective_date));

    const documentLinkValue = request['document_link'];
  
        // Check if the URL is provided and valid
        if (documentLinkValue && !isValidUrl(documentLinkValue)) {
          setDocValidationMessages({
              ...docValidationMessages,
              document_link: 'Please enter a valid URL.',
          });
          return; // Prevent form submission
      }
  
      // If validation passes, clear any existing validation messages
      setDocValidationMessages({
          ...docValidationMessages,
          document_link: '',
      });
  
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      if (plainText.trim().length === 0) {
        setErrors("The Description field is required.");
      }
    } else {
      try {
        setSubmitLoading(true);
        let userId = "";
        const userString = localStorage.getItem("user");
        if (userString) {
          const currentUser = JSON.parse(userString);
          userId = currentUser.id;
        }

        let payload = {
          title: request.title,
          content: contentData,
          author: request.author,
          act_name: { id: request.act_name },
          regulations: { id: request.regulations },
          ministries: { id: request.ministries },
          departments: { id: request.departments },
          notification_type: { id: request.notification_type },
          states: { id: request.states },
          country: { id: request.country_id },
          publication_date: publicationDate
            ? moment(publicationDate).format("YYYY-MM-DD")
            : "",
          effective_date: effectiveDate
          ? moment(effectiveDate).format("YYYY-MM-DD")
          : "",
          industries:
            selectedIndustryListIdsPayload.length > 0
              ? selectedIndustryListIdsPayload.map((item: object) => ({
                  id: getValue(item, `id`, ""),
                }))
              : [],

          tags:
            selectedTagsListIdsPayload.length > 0
              ? selectedTagsListIdsPayload.map((item: object) => ({
                  id: getValue(item, `id`, ""),
                }))
              : [],
          // documents: documents.map((item: object) => ({
          //   id: getValue(item, "id", ""),
          // })),
          ...(documents &&
            documents.length > 0 && {
              documents: documents.map((item: object) => ({
                id: getValue(item, "id", ""),
              })),
            }),
          // document_link: request.document_link,
          ...(request.document_link && request.document_link.trim() !== "" && {
            document_link: request.document_link,
          }),
        };
        if (plainText.trim().length === 0) {
          setErrors("The Description field is required.");
        } else {
          setErrors("");
          let resp;
          if (getValue(params, `id`, "")) {
            resp = await editLegalUpdate({
              id: parseInt(getValue(params, `id`, "")),
              ...payload,
            });

            if (resp) {
              setSubmitLoading(false);
              reset();
              // confirmNavigation();
              toast.success("Updated successfully");
              navigate(`/legal-update?${mainQueryRequest}`);
              setPublication_date(null);
              setEffective_date(null);
            } else {
              setSubmitLoading(false);
            }
          } else {
            resp = await createLegalUpdate(payload);
            if (resp) {
              setSubmitLoading(false);
              reset();
              // confirmNavigation();
              toast.success("Created successfully");
              navigate(`/legal-update?${mainQueryRequest}`);
            } else {
              setSubmitLoading(false);
            }
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const [docValidationMessages, setDocValidationMessages] = useState<{ [key: string]: string }>({});

// URL validation function
// const isValidUrl = (url: string) => {
//   const urlPattern = new RegExp(
//     "^(https?:\\/\\/)?" + // protocol (optional)
//     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name and extension
//     "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
//     "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
//     "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string (optional)
//     "(\\#[-a-z\\d_]*)?$", // fragment locator (optional)
//     'i'
// );
// return !!urlPattern.test(url);
// };

const isValidUrl = (url: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol (optional)
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name and extension
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string (optional)
    "(\\#[-a-z\\d_]*)?$", // fragment locator (optional)
    'i'
  );
  return !!urlPattern.test(url);
};

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country_id") {
      getStatesList(e.id);
      setRequest({
        ...request,
        country_id: e.id,
      });
    }

    if (type === "state_id") {
      setRequest({
        ...request,
        states: e.id,
      });
    }

    if (type === "regulations") {
      setRequest({
        ...request,
        regulations: e.id,
      });
    }

    if (type === "ministries") {
      setRequest({
        ...request,
        ministries: e.id,
      });
    }

    if (type === "departments") {
      setRequest({
        ...request,
        departments: e.id,
      });
    }

    if (type === "notification_type") {
      setRequest({
        ...request,
        notification_type: e.id,
      });
    }

    if (type === "act_name") {
      setRequest({
        ...request,
        act_name: e.id,
      });
    }
  };

  const handleTimeZone = (value: any) => {
    setValue(request, "user_timezone", value);
    setRequest({ ...request });
  };

  const handleChangeCountryCode = (e: any) => {
    setValue(request, "mobile_area_code", e);
    setRequest({ ...request });
  };

  const handleChangeRole = (e: any) => {
    setValue(request, "roles", e);
    setRequest({ ...request });
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  // const [
  //   showPrompt,
  //   confirmNavigation,
  //   cancelNavigation,
  // ]: any = useCallbackPrompt(!isChanged);

  const [countryList, setCountryList] = useState([]);

  const getCountriesList = async () => {
    try {
      let resp = await adminGetCountriesListManagement();
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [statesList, setStateList] = useState([]);

  //Single Select
  const getStatesList = async (countryId: number) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    try {
      let resp = await adminGetStatesListManagement(countryId);
      if (resp) {
        setStateList(
          getValue(resp, `data.states`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [regulationList, setRegulationList] = useState([]);
  const getRegulationList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("regulations");
      if (resp) {
        setRegulationList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            // is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [ministryList, setMinistryList] = useState([]);
  const getMinistryList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("ministries");
      if (resp) {
        setMinistryList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            // is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [departmentList, setDepartmentList] = useState([]);
  const getDepartmentList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("departments");
      if (resp) {
        setDepartmentList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            // is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [notificationTypeList, setNotificationTypeList] = useState([]);
  const getNotificationTypeList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("notification-type");
      if (resp) {
        setNotificationTypeList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            // is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };


  const [actNameList, setActNameList] = useState([]);
  const getActNameList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("act-name");
      if (resp) {
        setActNameList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  /* ----------------------Multi----------------------*/
  // // Multi States
  // const [statesList, setStatesList] = useState([]);
  // const [selectedStatesListIds, setSelectedStatesListIds] = useState<any>([]);
  // const handleChangeStateMulti = (e: any) => {
  //   setSelectedStatesListIds(e);
  // };

  // const getStateList = async (value: string, id: string, countryId: number) => {
  //   try {
  //     let payload = await getPayload();
  //     let queryRequest = QueryRequestHelper({ ...payload, country: "101" });
  //     // let resp = await adminGetStatesListManagement(queryRequest);
  //     let resp = await adminGetStatesListManagement(countryId);
  //     if (resp) {
  //       let list: any = getValue(resp, `data.states`, []).map(
  //         (item: object) => ({
  //           ...item,
  //           id: getValue(item, `id`, ""),
  //           value: getValue(item, `name`, ""),
  //           label: getValue(item, `name`, ""),
  //         })
  //       );

  //       let selectedIDs: any = urlSearchParams.getAll("states[]");
  //       setStatesList(list);

  //       // let selectedList =
  //       //   getValue(list, `length`, 0) > 0
  //       //     ? list.filter((item: object) =>
  //       //         selectedIDs.includes(getValue(item, `id`, []).toString())
  //       //       )
  //       //     : [];
  //       //     setSelectedStatesListIds(selectedList);
  //     } else {
  //       // setEventLoading(false);
  //     }
  //   } catch (error) {
  //     // setEventLoading(false);
  //   }
  //   if (!value) {
  //     setValue(request, `industry_id`, "");
  //   }
  // };

  // Tags List
  const [tagsList, setTagsList] = useState([]);
  const [selectedTagsListIds, setSelectedTagsListIds] = useState<any>([]);
  const handleChangeTagsMulti = (e: any) => {
    setSelectedTagsListIds(e);
  };

  const getTagsList = async (value: string, id: string) => {
    try {
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "tags",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        let list: any = getValue(resp, `data.listItems`, []).map(
          (item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          })
        );
        let selectedIDs: any = urlSearchParams.getAll("tags[]");
        setTagsList(list);

        // let selectedList =
        //   getValue(list, `length`, 0) > 0
        //     ? list.filter((item: object) =>
        //         selectedIDs.includes(getValue(item, `id`, []).toString())
        //       )
        //     : [];
        // setSelectedTagsListIds(selectedList);
      } else {
        // setEventLoading(false);
      }
    } catch (error) {
      // setEventLoading(false);
    }
    if (!value) {
      setValue(request, `industry_id`, "");
    }
  };
  //Industry List
  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustryListIds, setSelectedIndustryListIds] = useState<any>(
    []
  );
  const handleChangeSelectMulti = (e: any) => {
    setSelectedIndustryListIds(e);
  };

  const getIndustryList = async (value: string, id: string) => {
    try {
      let payload = {
        page_no: 1,
        page_size: 50,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "industry",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        let list: any = getValue(resp, `data.listItems`, []).map(
          (item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          })
        );
        let selectedIDs: any = urlSearchParams.getAll("industries[]");
        setIndustryList(list);

        // let selectedList =
        //   getValue(list, `length`, 0) > 0
        //     ? list.filter((item: object) =>
        //         selectedIDs.includes(getValue(item, `id`, []).toString())
        //       )
        //     : [];
        // setSelectedIndustryListIds(selectedList);
      } else {
        // setEventLoading(false);
      }
    } catch (error) {
      // setEventLoading(false);
    }
    if (!value) {
      setValue(request, `industry_id`, "");
    }
  };

  // const [regulationList, setRegulationList] = useState([]);
  // const [selectedRegulationListIds, setSelectedRegulationListIds] =
  //   useState<any>([]);

  // const handleChangeRegulationMulti = (e: any) => {
  //   setSelectedRegulationListIds(e);
  // };

  // const getRegulationList = async (value: string, id: string) => {
  //   try {
  //     let payload = {
  //       page_no: 1,
  //       page_size: 10,
  //       search_text: value,
  //       id: id,
  //       // group_id: "36", //
  //       group_code: "regulations",
  //     };
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await adminListManagementList(queryRequest);
  //     if (resp) {
  //       let list: any = getValue(resp, `data.listItems`, []).map(
  //         (item: object) => ({
  //           ...item,
  //           value: getValue(item, `text`, ""),
  //           label: getValue(item, `text`, ""),
  //         })
  //       );
  //       let selectedIDs: any = urlSearchParams.getAll("regulations[]");
  //       setRegulationList(list);

  //       // let selectedList =
  //       //   getValue(list, `length`, 0) > 0
  //       //     ? list.filter((item: object) =>
  //       //         selectedIDs.includes(getValue(item, `id`, []).toString())
  //       //       )
  //       //     : [];
  //       // setSelectedRegulationListIds(selectedList);
  //     } else {
  //       // setEventLoading(false);
  //     }
  //   } catch (error) {
  //     // setEventLoading(false);
  //   }
  //   if (!value) {
  //     setValue(request, `regulation_id`, "");
  //   }
  // };

  // const [ministryList, setMinistryList] = useState([]);
  // const [selectedMinistryListIds, setSelectedMinistryListIds] = useState<any>(
  //   []
  // );

  // const handleChangeMinistryMulti = (e: any) => {
  //   setSelectedMinistryListIds(e);
  // };

  // const getministryList = async (value: string, id: string) => {
  //   try {
  //     let payload = {
  //       page_no: 1,
  //       page_size: 10,
  //       search_text: value,
  //       id: id,
  //       // group_id: "36", //
  //       group_code: "ministries",
  //     };
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await adminListManagementList(queryRequest);
  //     if (resp) {
  //       let list: any = getValue(resp, `data.listItems`, []).map(
  //         (item: object) => ({
  //           ...item,
  //           value: getValue(item, `text`, ""),
  //           label: getValue(item, `text`, ""),
  //         })
  //       );
  //       let selectedIDs: any = urlSearchParams.getAll("ministries[]");
  //       setMinistryList(list);

  //       // let selectedList =
  //       //   getValue(list, `length`, 0) > 0
  //       //     ? list.filter((item: object) =>
  //       //         selectedIDs.includes(getValue(item, `id`, []).toString())
  //       //       )
  //       //     : [];
  //       // setSelectedMinistryListIds(selectedList);
  //     } else {
  //       // setEventLoading(false);
  //     }
  //   } catch (error) {
  //     // setEventLoading(false);
  //   }
  //   if (!value) {
  //     setValue(request, `ministry_id`, "");
  //   }
  // };

  // const [departmentList, setDepartmentlist] = useState([]);
  // const [selectedDepartmentListIds, setSelectedDepartmentListIds] =
  //   useState<any>([]);

  // const handleChangeDepartmentMulti = (e: any) => {
  //   setSelectedDepartmentListIds(e);
  // };

  // const getDepartmentList = async (value: string, id: string) => {
  //   try {
  //     let payload = {
  //       page_no: 1,
  //       page_size: 10,
  //       search_text: value,
  //       id: id,
  //       // group_id: "36", //
  //       group_code: "departments",
  //     };
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await adminListManagementList(queryRequest);
  //     if (resp) {
  //       let list: any = getValue(resp, `data.listItems`, []).map(
  //         (item: object) => ({
  //           ...item,
  //           value: getValue(item, `text`, ""),
  //           label: getValue(item, `text`, ""),
  //         })
  //       );
  //       let selectedIDs: any = urlSearchParams.getAll("departments[]");
  //       setDepartmentlist(list);

  //       // let selectedList =
  //       //   getValue(list, `length`, 0) > 0
  //       //     ? list.filter((item: object) =>
  //       //         selectedIDs.includes(getValue(item, `id`, []).toString())
  //       //       )
  //       //     : [];
  //       // setSelectedDepartmentListIds(selectedList);
  //     } else {
  //       // setEventLoading(false);
  //     }
  //   } catch (error) {
  //     // setEventLoading(false);
  //   }
  //   if (!value) {
  //     setValue(request, `department_id`, "");
  //   }
  // };

  // const [notificationTypeList, setNotificationTypeList] = useState([]);
  // const [selectedNotificationTypeListIds, setSelectedNotificationTypeListIds] =
  //   useState<any>([]);

  // const handleChangeNotificationTypeMulti = (e: any) => {
  //   setSelectedNotificationTypeListIds(e);
  // };

  // const getNotificationTypeList = async (value: string, id: string) => {
  //   try {
  //     let payload = {
  //       page_no: 1,
  //       page_size: 10,
  //       search_text: value,
  //       id: id,
  //       // group_id: "36", //
  //       group_code: "notification-type",
  //     };
  //     let queryRequest = QueryRequestHelper(payload);
  //     let resp = await adminListManagementList(queryRequest);
  //     if (resp) {
  //       let list: any = getValue(resp, `data.listItems`, []).map(
  //         (item: object) => ({
  //           ...item,
  //           value: getValue(item, `text`, ""),
  //           label: getValue(item, `text`, ""),
  //         })
  //       );
  //       let selectedIDs: any = urlSearchParams.getAll("notification_type[]");
  //       setNotificationTypeList(list);

  //       // let selectedList =
  //       //   getValue(list, `length`, 0) > 0
  //       //     ? list.filter((item: object) =>
  //       //         selectedIDs.includes(getValue(item, `id`, []).toString())
  //       //       )
  //       //     : [];
  //       // setSelectedNotificationTypeListIds(selectedList);
  //     } else {
  //       // setEventLoading(false);
  //     }
  //   } catch (error) {
  //     // setEventLoading(false);
  //   }
  //   if (!value) {
  //     setValue(request, `notification_type_id`, "");
  //   }
  // };

  const [isBulkUploadPopupVisible, setBulkuploadPopupVisibility] =
    useState(false);
  const [isDataImported, setIsDataImported] = useState(false);
  const [response, setResponse] = useState({});
  const [importType, setImportType] = useState("");
  // const [importedFile, setImportTedFile] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  // const [documents, setDocuments] = useState<any>([]);
  // const [uploadLoading, setUploadLoading] = useState(false);

  // const handleChangeUpload = async (e: any) => {
  //   // setImportTedFile(e.target.files[0]);
  //   // setImportTedFile(e[0]);

  //   Array.from(e).forEach(async (item: any) => {
  //     let formData = new FormData();
  //     formData.append("file", item);
  //     const newDocumentStoreIds = [...documents];
  //     try {
  //       setUploadLoading(true);

  //       // First API call
  //       let vendorResp = await vendorFileUpload(formData);
  //       if (vendorResp) {
  //         // Prepare data for the second API call
  //         let docFormData = new FormData();
  //         docFormData.append("file", item);
  //         // Add additional data required for docFileUpload if needed
  //         const newDocumentId = getValue(vendorResp, `data`, "");
  //         newDocumentStoreIds.push(newDocumentId);

  //         let payLoadObj = {
  //           checkpoint_id: getValue(request, `checkpoint_id`, ""),
  //           document_ids: [newDocumentId],
  //         };

  //         let docResp = await docStoreFileUpload(payLoadObj);
  //         if (docResp) {
  //           console.log(1412,getValue(docResp,`data.documents`, []))
  //           //  setDocuments(getValue(docResp,`data.documents`, []));
  //           getDocumentStore(getValue(request, `checkpoint_id`, ""));
  //         }
  //       }

  //       e = null;
  //       setUploadLoading(false);
  //     } catch (error) {

  //       console.log(1420,error)
  //       console.error(error);
  //       e = null;
  //       setUploadLoading(false);
  //     }
  //   });
  // };
  const closePopup = () => {
    setBulkuploadPopupVisibility(false);
    setIsDataImported(false);
    setImportTedFile("");
  };
  const importedSuccessfully = () => {
    setIsDataImported(!isDataImported);
  };
  const handleDownload = () => {
    var link = document.createElement("a");
    link.target = "_blank";
    link.download = "Contact";
    link.href = "url here";
    link.click();
  };

  // Editor Code
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const onEditorStateChange: Function = (editorState: any) => {
  //   setEditorState(editorState);
  // };

  //Upload Functionality
  //Docs
  // const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [uploadLoading, setUploadLoading] = useState(false);
  const [importedFile, setImportTedFile] = useState<any>(null);

  const [documents, setDocuments] = useState<any>([]);

  const handleUploadDocumentsStore = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadLoading(true);
        let vendorResp = await vendorFileUpload(formData);
        if (vendorResp) {
          documents.push(getValue(vendorResp, `data`, {}));
          setDocuments([...documents]);
        }

        setUploadLoading(false);
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  const handleRemoveUploadedFiles = (id: string) => {
    let filtered = documents.filter(
      (item: object) => getValue(item, `id`, "") !== id
    );
    setDocuments(filtered);
  };

  console.log(request, "===request==>")

  return (
    <>
      {/* <Header /> */}
      <Layout>
        {!loading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "legal_update_legal_update"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <div className="user-add-edit-main-details-wrap">
              {/* <PromptModal
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              /> */}
              {/* <AdminNavigation
                id={orgParams.id}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                url={`/admin/users`}
                isChanged={isChanged}
                permissions={props.permissions}
              /> */}

              {
                // loading?
                // <p className="text-center mt-2">Please wait...</p>:
                <div className="user-details-wrapper mt-0">
                  <div className="user-details-wrapper__header d-flex align-items-center justify-content-between">
                    <div
                      onClick={() => navigate(`/legal-update?${mainQueryRequest}`)}
                      className="user-details-wrapper__header-back-link py-2 cursor-pointer"
                    >
                      <img src="/images/back-link.svg" className="img-fluid" />
                    </div>
                    <div className="user-details-wrapper__navigation">
                      <a
                        href="#0"
                        className="user-details-wrapper__add-user-info"
                      >
                        Legal Details
                      </a>
                    </div>
                    <div className="user-details-wrapper__cta-btns">
                      {/* {getValue(props, `permissions`, []).includes(
                        "create"
                      ) && ( */}
                      <>
                        <div onClick={() => navigate(`/legal-update`)}>
                          <button className="user-details-wrapper__cta-btn user-details-wrapper__cta-btn--primary">
                            Cancel
                          </button>
                        </div>

                        <button
                          onClick={handleSubmit}
                          // disabled={isChanged}
                          className={`user-details-wrapper__cta-btn user-details-wrapper__cta-btn--${"secondary"}`}
                        >
                          Save
                        </button>
                      </>
                      {/* )} */}
                    </div>
                  </div>

                  {loading || !props.permissionAPITriggered ? (
                    <Loader />
                  ) : (
                    <div className="create-legal-update-scroll">
                      <div className="user-details-wrapper__form-wrapper">
                        <div className="">
                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label>
                              Title <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Title"
                              className="form-control w-100"
                              name="title"
                              id="title"
                              value={getValue(request, `title`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="First Name"
                              validator={simpleValidator}
                            />
                          </div>
                        </div>

                        {/* <div className="user-details-wrapper__form w-100 d-flex">
                          <div className="user-details-wrapper__form-group form-group mb-3 w-100">
                            <label>
                              Description <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXTAREA"
                              placeholder="Enter Description"
                              className="form-control w-100"
                              name="content"
                              id="content"
                              value={getValue(request, `content`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="First Name"
                              validator={simpleValidator}
                            />
                          </div>
                        </div> */}

                        <div className="user-details-wrapper__form w-100 d-flex">
                          <div className="user-details-wrapper__form-group form-group mb-3 w-100">
                            <label>
                              Description <span>*</span>
                            </label>
                            <Editor
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={onEditorStateChange}
                              placeholder={"Enter Description"}
                            />
                            {errors && (
                              <div className="form-error">{errors}</div>
                            )}
                          </div>
                        </div>

                        <div className="user-details-wrapper__form">
                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="country_id"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Country <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input select-dd-new">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose Country"
                                className="form-control w-100"
                                name="country_id"
                                id="country_id"
                                label="Country"
                                // options={statesList}
                                // value={selectedStatesListIds}
                                // onChange={(e: any) => handleChangeStateMulti(e)}
                                // isMulti
                                options={countryList}
                                value={countryList.filter(
                                  (item: object) =>
                                    getValue(item, `id`, "") ==
                                    getValue(request, `country_id`, "")
                                )}
                                onChange={(e: any) =>
                                  handleChangeSelect(e, "country_id")
                                }
                                validator={simpleValidator}
                              />
                            </div>
                          </div>

                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="state_id"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              State <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input select-dd-new">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose State"
                                className="form-control w-100"
                                name="state_id"
                                id="state_id"
                                label="State"
                                // options={statesList}
                                // value={selectedStatesListIds}
                                // onChange={(e: any) => handleChangeStateMulti(e)}
                                // isMulti
                                options={statesList}
                                value={statesList.filter(
                                  (item: object) =>
                                    getValue(item, `id`, "") ==
                                    getValue(request, `states`, "")
                                )}
                                onChange={(e: any) =>
                                  handleChangeSelect(e, "state_id")
                                }
                                validator={simpleValidator}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="user-details-wrapper__form">
                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="tags_id"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Tags <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input select-dd-new">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose Tags"
                                className="form-control w-100"
                                name="tags_id"
                                id="tags_id"
                                label="Tags"
                                options={tagsList}
                                // options={states}
                                value={selectedTagsListIds}
                                // value={getValue(request, `tags`, "")}

                                // value={props.state_ID}
                                onChange={(e: any) => handleChangeTagsMulti(e)}
                                // onChange={(e: any) =>
                                //   handleChangeSelect(e, "state_id")
                                // }
                                // isClear
                                isMulti
                                validator={simpleValidator}
                              />
                            </div>
                          </div>

                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label>
                              Industries <span>*</span>
                            </label>
                            <InputField
                              inputType="INPUT_REACT_SELECT"
                              placeholder="Choose Industry"
                              className="form-control"
                              name="industry_id"
                              id="industry_id"
                              label="Industry"
                              options={industryList}
                              value={selectedIndustryListIds}
                              onChange={(e: any) => handleChangeSelectMulti(e)}
                              isMulti
                              validator={simpleValidator}
                            />
                          </div>

                          
                        </div>

                        <div className="user-details-wrapper__form">
                        <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="regulation_id"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Regulation/Rule/Direction/Master Circular <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose Regulation"
                                className="form-control"
                                name="regulation_id"
                                id="regulation_id"
                                label="Regulation"
                                // options={regulationList}
                                // value={selectedRegulationListIds}
                                // onChange={(e: any) =>
                                //   handleChangeRegulationMulti(e)
                                // }
                                // isMulti
                                options={regulationList}
                                value={regulationList.filter(
                                  (item: object) =>
                                    getValue(item, `id`, "") ==
                                    getValue(request, `regulations`, "")
                                )}
                                onChange={(e: any) =>
                                  handleChangeSelect(e, "regulations")
                                }
                                validator={simpleValidator}
                              />
                            </div>
                          </div>

                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="rule"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Ministry <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose Ministry"
                                className="form-control"
                                name="legislation_rule_id"
                                id="legislation_rule_id"
                                label="Ministry"
                                options={ministryList}
                                // value={selectedMinistryListIds}
                                // onChange={(e: any) =>
                                //   handleChangeMinistryMulti(e)
                                // }
                                // isMulti
                                value={ministryList.filter(
                                  (item: object) =>
                                    getValue(item, `id`, "") ==
                                    getValue(request, `ministries`, "")
                                )}
                                onChange={(e: any) =>
                                  handleChangeSelect(e, "ministries")
                                }
                                validator={simpleValidator}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="user-details-wrapper__form">
                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="rule"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Department <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose Department"
                                className="form-control"
                                name="department"
                                id="department"
                                label="Department"
                                options={departmentList}
                                // value={selectedDepartmentListIds}
                                // onChange={(e: any) =>
                                //   handleChangeDepartmentMulti(e)
                                // }
                                // isMulti
                                value={departmentList.filter(
                                  (item: object) =>
                                    getValue(item, `id`, "") ==
                                    getValue(request, `departments`, "")
                                )}
                                onChange={(e: any) =>
                                  handleChangeSelect(e, "departments")
                                }
                                validator={simpleValidator}
                              />
                            </div>
                          </div>

                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="rule"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Notification Type <span>*</span>
                            </label>
                            <div className="filter-form-wrappper__location-input">
                              <InputField
                                inputType="INPUT_REACT_SELECT"
                                placeholder="Choose Notification Type"
                                className="form-control"
                                name="notification_type"
                                id="notification_type"
                                label="Notification Type"
                                options={notificationTypeList}
                                value={notificationTypeList.filter(
                                  (item: object) =>
                                    getValue(item, `id`, "") ==
                                    getValue(request, `notification_type`, "")
                                )}
                                onChange={(e: any) =>
                                  handleChangeSelect(e, "notification_type")
                                }
                                validator={simpleValidator}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="user-details-wrapper__form">
                        <div className="user-details-wrapper__form-group form-group mb-3">
                            <label>
                              Act Name <span>*</span>
                            </label>

                            <InputField
                              inputType="INPUT_REACT_SELECT"
                              placeholder="Choose Act Name"
                              className="form-control"
                              name="act_name"
                              id="act_name"
                              label="Act Name"
                              options={actNameList}
                              value={actNameList.filter(
                                (item: object) =>
                                  getValue(item, `id`, "") ==
                                  getValue(request, `act_name`, "")
                              )}
                              onChange={(e: any) =>
                                handleChangeSelect(e, "act_name")
                              }
                              validator={simpleValidator}
                            />
                          </div>

                        <div className="user-details-wrapper__form-group form-group mb-3">
                            <label>
                              Author <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Author"
                              className="form-control w-100"
                              name="author"
                              id="author"
                              value={getValue(request, `author`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Author"
                              validator={simpleValidator}
                            />
                          </div>

                          
                        </div>

                        <div className="user-details-wrapper__form">
                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="rule"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Publication Date <span>*</span>
                            </label>

                            <InputField
                              inputType="DATETYPE"
                              className="form-control"
                              placeholder="Enter Publication Date"
                              value={publication_date}
                              onChange={(e: any) => setPublication_date(e)}
                              maxDate={new Date()}
                            />
                          </div>

                          <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="rule"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Effective Date <span>*</span>
                            </label>

                            <InputField
                              inputType="DATETYPE"
                              className="form-control"
                              placeholder="Enter Effective Date"
                              value={effective_date}
                              onChange={(e: any) => setEffective_date(e)}
                              minDate={new Date(publication_date)}
                            />
                          </div>

                          
                          </div>
                        

                        <div className="user-details-wrapper__form">
                        <div className="user-details-wrapper__form-group form-group mb-3">
                            <label
                              htmlFor="rule"
                              className="filter-form-wrappper__label filter-fields-label"
                            >
                              Document Url
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Title"
                              className="form-control w-100"
                              name="document_link"
                              id="document_link"
                              value={getValue(request, `document_link`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Document Url"
                              // validator={simpleValidator}
                            />
                            {docValidationMessages.document_link && (
                                <span className="form-error">
                                  {docValidationMessages.document_link}
                                </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <DragAndDropFileComponent
                            uploadFile={handleUploadDocumentsStore}
                            name="file"
                            types={fileTypes}
                            multiple
                            // className="d-none"
                            classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                            fileName={getValue(request, `documents`, "")}
                            setImportTedFile={setImportTedFile}
                            file={"false"}
                          />
                          {documents.map(
                            (item: any) => (
                              (
                                <div
                                  // className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between mt-2"
                                  key={item.id}
                                >
                                  <>
                                    <div
                                      className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between mt-2"
                                      key={item.id}
                                    >
                                      <p
                                        key={item.id}
                                        style={{
                                          fontSize: "12px",
                                          marginTop: "0px",
                                          textAlign: "center",
                                        }}
                                        onClick={() =>
                                          handleDownloadOnClick(
                                            item.url,
                                            item.name
                                          )
                                        }
                                      >
                                        {formatSmallString(item.name)}
                                      </p>
                                      <img
                                        src="/images/close-red.svg"
                                        className="img-fluid"
                                        style={{ marginLeft: "7px" }}
                                        // onClick={() => setImportTedFile("")}
                                        onClick={() =>
                                          handleRemoveUploadedFiles(item.id)
                                        }
                                      />
                                    </div>
                                  </>
                                </div>
                              )
                            )
                          )}
                        </div>
                        </div>
                      </div>
                    // </div>
                  )}
                </div>
              }
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default LegalUpdateDetailsInfo;
